@tailwind base;
@tailwind components;
@tailwind utilities;

/* browser autoFill styling (custom) */

input:-webkit-autofill,
input:-webkit-autofill:focus {
	transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
	background-color: transparent !important;
}

/* Customising ScrollBar */
::-webkit-scrollbar {
	width: 0.8em;
}

::-webkit-scrollbar-thumb {
	background-color: hsla(0, 0%, 10%, 1);
	border: 2px solid hsla(0, 0%, 50%, 1);
}

::-webkit-scrollbar-track {
	background-color: hsla(0, 0%, 50%, 1);
}

:root {
	--color-1: #fe0000;
	--color-2: #292929;
}

.logoHighlightColor {
	color: var(--color-1) !important;
}

.topNavbarWrapper {
	background: #fff !important;
	/* padding: 10px 100px; */
}

.topNavbar {
	background: #fff !important;
}

.headerLogo {
	font-size: 1.5rem !important;
}

.navbarMenuWrapper a {
	border-radius: 0 !important;
	border: none !important;
	color: var(--color-2) !important;
}

.navbarMenuWrapper a span {
	text-transform: uppercase !important;
	font-size: 0.8rem;
	font-weight: bold;
}

.navbarMenuWrapper a:hover span {
	color: var(--color-1);
	transition: 0.1s 0.1s;
}

.navbarMenuWrapper .active span {
	color: var(--color-1);
}

.redButton {
	background: var(--color-1) !important;
	border-radius: 0;
	border: 0;
	color: #f9f9f9 !important;
	font-size: 1.5rem;
	font-weight: 400;
	padding: 8px 20px;
	transition: 0.1s 0.1s;
	margin: 5px;
}
.redButton span {
	color: #f9f9f9 !important;
}

.redButton:hover {
	background: var(--color-2) !important;
	color: #fff !important;
}
.redButton:hover span {
	color: #fff !important;
}

.redButton .active a {
	color: var(--color-1) !important;
}

.bigButton {
	margin-top: 100px;
	border: none !important;
	margin-bottom: 50px;
}

.bigButton span {
	font-size: 2rem !important;
	padding: 10px 25px;
	text-transform: uppercase;
}

.heroTitle {
	display: block !important;
}

.sliderAnimateColor {
	text-align: center;

	background: linear-gradient(
		to left top,
		rgb(10, 252, 131) 10%,
		rgb(4, 179, 27) 25%,
		rgb(0, 170, 23) 50%,
		rgb(20, 255, 118) 75%,
		rgb(2, 177, 25) 90%
	);
	background-size: 200% auto;

	color: #000;
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;

	animation: shine 1s linear infinite;
}
@keyframes shine {
	to {
		background-position: 200% center;
	}
}

@media only screen and (max-width: 767px) {
	.topNavbarWrapper {
		background: #fff !important;
		padding: 10px 30px !important;
	}

	.mobileMenu {
		min-width: 300px;
	}

	.mobileMenu {
		min-width: 300px;
	}

	.mobileMenu a {
		border-radius: 0 !important;
		border: none !important;
		color: var(--color-2) !important;
		/* border-bottom: 1px solid #999 !important; */
	}

	.mobileMenu a :hover {
		color: #fff !important;
		/* border-bottom: 1px solid #999 !important; */
	}

	.mobileMenu a span {
		text-transform: uppercase !important;
		font-size: 0.8rem;
		font-weight: bold;
		color: var(--color-2) !important;
		padding: 10px;
	}

	.mobileMenu .redButton span {
		color: #fff !important;
	}

	.heroTitle {
		font-size: 2rem;
		margin-top: 150px;
	}
}
